function isScrolledIntoView(el) {
	var rect = el.getBoundingClientRect();
	var elemTop = rect.top;
	var elemBottom = rect.bottom;
	var isVisible;

	// Only completely visible elements return true:
	// isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight);
	// Partially visible elements return true:
	isVisible = elemTop < window.innerHeight && elemBottom >= 0;
	return isVisible;
}

document.addEventListener('DOMContentLoaded', function(){

	const header = document.querySelector('.h'),
			burgerBtn = document.querySelector('.h__burger'),
			logoBtn = document.querySelector('.h__logo');

	burgerBtn.addEventListener('click', () => {
		header.classList.toggle('h--opened');
	});

	logoBtn.addEventListener('click', () => {
		window.scrollTo({top: 0, behavior: 'smooth'});
	})

	function checkScroll() {
		if (window.scrollY > 10){
			header.classList.add('h--colored')
		} else {
			header.classList.remove('h--colored')
		}
	}

	if (header){
		window.onscroll = function() {
			requestAnimationFrame(checkScroll)
		};
	}
	

	// counters
	function animateValue(obj, start, end, duration = 2000) {
		let startTimestamp = null;
		const step = (timestamp) => {
			if (!startTimestamp) startTimestamp = timestamp;
			const progress = Math.min((timestamp - startTimestamp) / duration, 1);
			const newValue = progress * (end - start) + start;
			if (obj.hasAttribute('data-decimal')) {
				obj.innerHTML = newValue.toFixed(1);
			} else {
				obj.innerHTML = Math.floor(newValue).toLocaleString();
			}
			if (progress < 1) {
				window.requestAnimationFrame(step);
			}
		};
		window.requestAnimationFrame(step);
	}
	
	const counters = document.querySelectorAll('.map__digit-value span');
	counters.forEach(counter => {
		const end = Number(counter.textContent);
		animateValue(counter, 0, end);
	});


	// keys
	const keyBtns = document.querySelectorAll('.key__button');
	keyBtns.forEach((btn) => {
		btn.addEventListener('click', () => {
			if (btn.classList.contains('key__button--active')) {
				return;
			}

			const oldBtn = document.querySelector('.key__button--active');
			oldBtn?.classList.remove('key__button--active');
			btn.classList.add('key__button--active');

			// old 
			const oldPoster = document.querySelector('.key__poster-container--active'),
						target = btn.dataset.target,
						targetEl = document.querySelector(`[${target}]`);
			oldPoster?.classList.remove('key__poster-container--active');
			targetEl?.classList.add('key__poster-container--active');
		})
	})


	// keys
	const sliderEl = document.querySelector('.case__slider'),
				activeSlide = document.getElementById('activeSlide'),
				totalSlide = document.getElementById('totalSlide');

	const swiper = new Swiper(sliderEl, {
		spaceBetween: 30,
		navigation: {
			nextEl: document.querySelector('.case__navigation-btn.case__navigation-btn--next'),
			prevEl: document.querySelector('.case__navigation-btn.case__navigation-btn--prev'),
		},
		breakpoints: {
			600: {
				slidesPerView: 2
			},
			992: {
				slidesPerView: 3
			}
		},
		// on: {
		// 	init: function () {
		// 		totalSlide.textContent = this.slides.length;
		// 		activeSlide.textContent = this.realIndex + 1;
		// 	},
		// 	slideChange: function() {
		// 		activeSlide.textContent = this.realIndex + 1;
		// 	}
		// },
	})


	// popups
	const closePopup = popup => {

		popup.classList.remove('popup--active');

		document.body.style.overflow = '';
	}

	document.addEventListener('click', function(e){
		const tg = e.target;

		if (tg.classList.contains('popup')) {
			closePopup(tg);
			return;
		}

		if (tg.closest('.popup__close-btn')) {
			const popup = tg.closest('.popup');			
			closePopup(popup);
		}
	});
	
	document.addEventListener('keyup', function(e){
		if (e.keyCode == 27) {
			const popup = document.querySelector('.popup--active');
			if (popup) {
				closePopup(popup);
			}
		}		
	});

	// open popups
	document.addEventListener('click', function(e){
		const target = e.target.closest('[data-popup-target]');
		
		if (target) {

			const popup = document.getElementById(target.dataset.popupTarget);
			
			popup.classList.add('popup--active');
			document.body.style.overflow = 'hidden';
		
		}
	});



	// anchors
	const anchorLinks = document.querySelectorAll('[data-anchor-link]');
	anchorLinks.forEach(function(link){
		link.addEventListener('click', function(e){
			e.preventDefault();

			const target = link.getAttribute('href').substring(1),
						targetEl = document.getElementById(target);
			let offset = targetEl.getBoundingClientRect().top + window.scrollY,
				headerHieght = document.querySelector('header').scrollHeight;

			offset -= headerHieght;

			if (header.classList.contains('h--opened')) {
				header.classList.remove('h--opened')
			}
			
			window.scrollTo({top: offset, behavior: 'smooth'});
		});
	});


	// init phones
	const telephoneInputs = document.querySelectorAll('[data-input-tel]');
	telephoneInputs.forEach(function (telephoneInput) {
		IMask(telephoneInput, {
			mask: '+{7}(000)000-00-00'
		});
	});
});



document.addEventListener('DOMContentLoaded', () => {

	// Form validation
	function initSimpleForm(form) {

		var requireElements = form.querySelectorAll('[required], .js-validate'),
			submitBtn = form.querySelector('.js-btn-submit'),
			agreeCheck = form.querySelector('.js-form-check-rules'),
			state = submitBtn.textContent;

		function clearFormErrors() {
			var texts = form.querySelectorAll('.input-line__error-text:not(.hidden)');
			texts.forEach(function (text) {
				text.classList.add('hidden');
			});

			var styles = form.querySelectorAll('.has-error');
			styles.forEach(function (style) {
				style.classList.remove('has-error');
			});
		}

		function removeSingleErrorNode() {
			var isErrorNode = this.classList.contains('has-error');

			if (isErrorNode) {
				this.classList.remove('has-error');
				var errorText = this.closest('.input-line').querySelector('.input-line__error-text');
				if (errorText) {
					errorText.classList.add('hidden');
				}
			}
		}

		function scrollToFirstError() {
			var errEl = form.querySelector('.has-error');

			if (errEl && !isScrolledIntoView(errEl)) {
				errEl.closest('.input-line').scrollIntoView({
					behavior: 'smooth'
				});
			}
		}

		function applyAjaxResult(data) {
			if (!data.err) {

				if (data.elementsToHideInSuccess) {
					data.elementsToHideInSuccess.forEach(function (selector) {
						var el = form.querySelector(selector);
						el.style.display = 'none';
					})
				}

				if (data.successHtml) {
					var successTarget = form.querySelector(data.successTarget);
					successTarget.innerHTML = data.successHtml;
					successTarget.style.display = '';
				}

			} else {

				data.elements.forEach(function (el) {
					if (el.errName == "captcha") {
						const errWrp = form.querySelector('.js-form-common-error');
						errWrp.textContent = el.message;
						errWrp.classList.remove('hidden');
					} else {
						var errEl = form.querySelector('[name="' + el.errName + '"]');
						if (errEl) {
							errEl.classList.add('has-error');
							var errText = errEl.closest('.input-line').querySelector('.input-line__error-text');
							errText.textContent = el.message;
							errText.classList.remove('hidden');
						}
					}
				});

				scrollToFirstError();
			}

			submitBtn.textContent = state;
			submitBtn.disabled = false;
		}

		requireElements.forEach(function (el) {
			el.addEventListener('input', removeSingleErrorNode);
			el.addEventListener('change', removeSingleErrorNode);
		});

		if (agreeCheck) {
			agreeCheck.addEventListener('change', function () {
				submitBtn.disabled = !this.checked;
			});
		}

		form.setAttribute('novalidate', '');
		form.addEventListener('submit', function (e) {
			e.preventDefault();

			clearFormErrors();

			var bError = false;
			requireElements.forEach(function (el) {

				if (!el.checkValidity() || 
					(el.hasAttribute('data-input-tel') && el.value.length < 16) ||
					(el.hasAttribute('data-input-tel') && el.value[3] != 9)
				) {
					var errorType = el.validity.valueMissing ? 'valueMissing' : 'valueInvalid';
					var errorEl = el.closest('.input-line').querySelector('.input-line__error-text');
					if (errorEl) {
						errorEl.innerHTML = el.dataset[errorType];
						errorEl.classList.remove('hidden');
					}
					el.classList.add('has-error');

					bError = true;
				}
			});

			if (bError) {
				scrollToFirstError();
				return;
			}

			let data = new FormData(form);
			submitBtn.disabled = true;
			submitBtn.textContent = submitBtn.dataset.wait;

			fetch(form.getAttribute('action'), {
				method: form.getAttribute('method'),
				body: data
			}).then(response => {
				return response.json();
			}).then(data => {
				applyAjaxResult(data);
			}).catch(function (error) {
				console.error(error);
			});

			// send comagic for quiz form
			if (form.hasAttribute('data-quiz-form') && typeof Comagic != 'undefined') {
				
				const name = data.get('name');
				const phone = data.get('phone');
				data.delete('name');
				data.delete('phone');

				const dataObj = {};
    		data.forEach((value, key) => (dataObj[key] = value));

				Comagic.addOfflineRequest({
					name: name,
					phone: phone,
					form_name: form.getAttribute('name'),
					user_fields: {
						...dataObj
					}
				});
			}
		});
	}

	var forms = document.querySelectorAll('form.js-simple-submit');
	if (!forms.length) {
		return;
	}

	forms.forEach(form => {
		initSimpleForm(form);
	});
})